import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import Styles from './pricing.styles';
import { Col, Container, Row, Card, Button } from 'react-bootstrap';
import TryItBanner from '../components/tryItBanner/tryItBanner';

const PricingPage = ({ data }) => {
  return (
    <Layout>
      <Seo 
        title={data.contentfulSitePricing.metaContent.title} 
        description={data.contentfulSitePricing.metaContent.description}
      />
      <Styles>
        <header style={{ minHeight: '0' }} className={`my-4`}>
          <Container>
            <Row className={`text-center justify-content-center`}>
              <Col md={10} lg={8}>
                <h1>{data.contentfulSitePricing.headline}</h1>
                <p>{data.contentfulSitePricing.subheadline}</p>
              </Col>
            </Row>
          </Container>
        </header>

        <section className={`mt-4 mb-5`} id={`advisorPricing`}>
          <Container>
            <Row className={`px-sm-0 px-md-4 row-eq-height`}>
              <Col md={12} lg={6} className={`mb-3`}>
                <Card className={`p-sm-0 p-lg-3 h-100 text-center`}>
                  <Card.Body>
                    <div
                      className={`mt-2 mb-4 price-card`}
                      dangerouslySetInnerHTML={{
                        __html:
                          data.contentfulSitePricingOption1TextNode
                            .childMarkdownRemark.html,
                      }}
                    />
                    <a href={`https://app.boardhealthscore.com`} target={`_blank`} className={`btn btn-primary`}>
                      {data.contentfulSitePricing.buyButton}
                    </a>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={12} lg={6} className={`mb-3`}>
                <Card className={`p-sm-0 p-lg-3 h-100 text-center`}>
                  <Card.Body>
                    <div
                      className={`mt-2 mb-4 price-card`}
                      dangerouslySetInnerHTML={{
                        __html:
                          data.contentfulSitePricingOption2TextNode
                            .childMarkdownRemark.html,
                      }}
                    />
                    <Button className={`btn btn-primary disabled`}>
                      Coming Soon
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <TryItBanner />
      </Styles>
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulSitePricing {
      metaContent {
        title
        description
      }
      headline
      subheadline
      buyButton
    }
    contentfulSitePricingOption1TextNode {
      childMarkdownRemark {
        html
      }
    }
    contentfulSitePricingOption2TextNode {
      childMarkdownRemark {
        html
      }
    }
  }
`;

export default PricingPage;
