import styled from '@emotion/styled';
import rhythm from '../utils/rhythm';

const Styles = styled.div({
  '.price-card': {
    '>p': {
      marginTop: rhythm(1),
      marginBottom: rhythm(1),
    },

    ul: {
      marginTop: 0,
      marginBottom: 0,
      textAlign: 'left',

      p: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
});

export default Styles;
