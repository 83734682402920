import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Styles from './tryItBanner.styles';
import { Col, Container, Row } from 'react-bootstrap';
import Img from 'gatsby-image';

export default function TryItBanner() {
  const data = useStaticQuery(graphql`
    query TryItBannerQuery {
      contentfulSiteBanners {
        demoHeadline
        demoSubheadline
        demoButton
        demoImage {
          sizes {
            ...GatsbyContentfulSizes
          }
        }
      }
    }
  `);

  return (
    <Styles className={`py-5 bg-light`}>
      <Container>
        <Row className={`align-items-center`}>
          <Col md={3} className={`d-none d-md-block`}>
            <Img
              fluid={data.contentfulSiteBanners.demoImage.sizes}
              alt={`alt text`}
            />
          </Col>

          <Col xs={12} md={8}>
            <h3 className={`mb-2`}>
              {data.contentfulSiteBanners.demoHeadline}
            </h3>
            <h5 className={`mb-3`}>
              {data.contentfulSiteBanners.demoSubheadline}
            </h5>
            <a href={`https://app.boardhealthscore.com/`} target={`_blank`} className={`btn btn-primary`}>
              {/*{data.contentfulSiteBanners.demoButton}*/}
              Get Started
            </a>
          </Col>
        </Row>
      </Container>
    </Styles>
  );
}
